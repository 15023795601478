import React from 'react';
import './Product.css';
import 'antd/dist/antd.css';
import { Icon, Card, List, Layout } from 'antd';
import { isBrowser, isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import { ProductList } from '../../config/Configs.js'
import ReactGA from 'react-ga';

const productIds = [];
for (var i in ProductList) {
    productIds.push(i);
}

class Products extends React.Component {


    render() {
        ReactGA.initialize("UA-63905846-9");
        ReactGA.set({ dimension1: "IRX Quickdemo" });
        ReactGA.pageview("Landing Page");
        return (<>
            <script src="https://app.awesome-table.com/AwesomeTableInclude.js"></script>
            <div data-type="AwesomeTableView" data-filters="" data-viewID="-MkcZQE8nhqDKCVUFn9R"></div>
        </>
        );
    }
}

export default Products;